const cookieName = "comments";
const hasCommentsEnabled = getCookieValue(cookieName);
const modal = document.querySelector(".modal");

if (hasCommentsEnabled === true) {
  injectScripts();
} else if (hasCommentsEnabled === false) {
  addNoCommentsClass();
} else {
  toggleModal();
}

function getCookieValue(a) {
  var b = document.cookie.match(`(^|;)\\s*${a}\\s*=\\s*([^;]+)`);
  return b ? Boolean(parseInt(b.pop()), 10) : null;
}

function setCommentCookie(isEnabled) {
  document.cookie = `${cookieName}=${isEnabled ? 1 : 0};path=/;samesite=strict;secure`;
}

function addNoCommentsClass() {
  document.body.classList.add("no-comments");
}

function toggleModal() {
  const display = modal.style.display;
  const acceptCTA = document.querySelector('.modal__cta-accept');
  const rejectCTA = document.querySelector('.modal__cta-reject');

  if (display === "none") {
    acceptCTA.addEventListener('click', acceptScripts);
    rejectCTA.addEventListener('click', rejectScripts);

    modal.setAttribute("style", "");
  } else {
    modal.setAttribute("style", "display: none;");
  }
}

function injectScripts() {
  // <script id="dsq-count-scr" src="//smartart.disqus.com/count.js" async></script>
  const commentCountScript = document.createElement("script");
  (document.head || document.body).appendChild(commentCountScript);
  commentCountScript.async = true;
  commentCountScript.id = "dsq-count-scr";
  commentCountScript.src = "//smartart.disqus.com/count.js";

  const commentEmbedScript = document.createElement("script");
  (document.head || document.body).appendChild(commentEmbedScript);
  commentEmbedScript.src = "//smartart.disqus.com/embed.js";
  commentEmbedScript.setAttribute("data-timestamp", +new Date());
}

function rejectScripts() {
  setCommentCookie(false);
  addNoCommentsClass();
  toggleModal();
}

function acceptScripts() {
  setCommentCookie(true);
  injectScripts();
  toggleModal();
}
